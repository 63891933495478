import { Modal } from '@Z/components/atoms';
import React, { useEffect } from 'react';
import DownloadModalWrapper from './styles';
import { Col, Row } from 'antd';
import { Image } from 'antd';
import LogoOpenLocker from '@iso/assets/images/logo/open-locker.png';
import LogoZezamii from '@iso/assets/images/logo/IconBlue.png';
import { useHistory } from 'react-router-dom';
import useWindowSize from '@iso/lib/hooks/useWindowSize';

const DownloadApplicationModal = ({ viewModal = false, setViewModal }) => {
	const { width } = useWindowSize();
	const history = useHistory();

	useEffect(() => {
		// Check if the current pathname matches the route for the 404 page
		if (width < 576) {
			setViewModal(true);
		} else {
			setViewModal(false);
		}
	}, [history, width, setViewModal]);

	const Applications = [
		{
			iconApp: LogoZezamii,
			nameApp: 'Zezamii App',
			linkPlayStore: 'https://play.google.com/store/apps/details?id=com.zezamii.zezamiiappv2',
			linkAppStore: 'https://apps.apple.com/au/app/zezamii-app/id1630870967'
		},
		{
			iconApp: LogoOpenLocker,
			nameApp: 'Open Locker App',
			linkPlayStore: 'https://play.google.com/store/apps/details?id=com.zezamii.openlocker',
			linkAppStore: 'https://apps.apple.com/au/app/open-locker/id6455258264'
		}
	];

	return (
		<Modal
			title={'Download Our Mobile Apps'}
			visible={viewModal}
			onCancel={() => {
				setViewModal(false);
			}}
			width={650}
			maskClosable={true}
			destroyOnClose={true}
			footer={null}
		>
			<DownloadModalWrapper>
				<Row className='row' gutter={[5, 10]}>
					{Applications.map((app) => {
						return (
							<Col lg={12} md={12} sm={24} xs={24} className='col'>
								<Row align='middle'>
									<Image src={app.iconApp} width={30} height={30} preview={false} alt={app.nameApp} />
									<h1>{app.nameApp}</h1>
								</Row>
								<h3>Download Zezamii from Play Store or App Store and Scan QR Code on Sign-up</h3>
								<Row align='middle'>
									<a href={app.linkPlayStore} target='_blank' rel='noopener noreferrer'>
										<Image
											width={140}
											height={57}
											alt='Get it on Google Play'
											src={
												'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
											}
											preview={false}
										/>
									</a>
									<a href={app.linkAppStore} target='_blank' rel='noopener noreferrer'>
										<Image
											width={120}
											height={40}
											src={
												'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1691625600'
											}
											alt='Get it on App Store'
											preview={false}
										/>
									</a>
								</Row>
							</Col>
						);
					})}
				</Row>
			</DownloadModalWrapper>
		</Modal>
	);
};

export default DownloadApplicationModal;
